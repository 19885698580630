import React, { useState } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import Img from 'gatsby-image'
import { Theme } from '@pga/pga-component-library'

export const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  margin-bottom: 2rem;
`

export const Hero = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${Theme.colors.secondary};
  margin: 0;
  height: 186px;

  @media (min-width: 576px) {
    height: 350px;
  }
  &:after {
    background-image: ${`linear-gradient(180deg, ${Theme.colors.primaryOpaque} ,${Theme.colors.primary})`};
    bottom: 0;
    content: "";
    left: 0;
    opacity: .7;
    position: absolute;
    right: 0;
    top: 70%;
  }

`

export const HeroBody = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
`

export const Content = styled.div.attrs({
  className: 'col'
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`
export const Title = styled.h1`
  font-family: ${Theme.fonts.PlayfairDisplay};
  font-size: 40px;
  font-weight: 700;
  color: ${Theme.colors.primary};

  @media (max-width: 767.99px) {
    font-size: 40px;
  }

  @media (max-width: 576.99px) {
    font-size: 28px;
    line-height: 0.95;
  }

  @media (max-width: 359.99px) {
    font-size: 28px;
    line-height: 0.95;
  }
`

const Image = styled(Img)`
  position: initial !important;
`

export const InputWrapper = styled.div`
  box-shadow: 0 5px 10px 0 rgba(0,35,75,.15);
  margin-top: 16px;
  width: 60%;
  display: flex;
  input {
    border: none;
    width: 100%;
    @media (max-width: 767.98px) {
      margin-bottom: 16px;
    }
  }
  @media (max-width: 767.98px) {
    flex-direction: column;
  }
`

export const SearchButton = styled.button`
  background-color: ${Theme.colors.lightBlue};
  color: white;
  text-transform: uppercase;
  border: 0;
  padding: 6px 25px;
  font-family: ${Theme.fonts.Montserrat};
  font-size: 14px;
  transition: .3s;
`

const HeroImageWrapper = ({ childImageSharp }) => {
  const [input, setInput] = useState('')

  const handleSubmit = () => {
    const state = { query: input }
    navigate('/search', { state })
  }

  return (
    <HeroContainer>
      <Hero>
        <Image fluid={childImageSharp.fluid} />
        <HeroBody>
          <div className='container'>
            <div className='row'>
              <Content>
                <Title>Member Resources</Title>
                <InputWrapper>
                  <input
                    autoFocus
                    className='form-control'
                    type='search'
                    placeholder='Search News and Resources...'
                    onChange={e => setInput(e.target.value)}
                    onKeyDown={e => e.keyCode === 13 ? handleSubmit() : null}
                    value={input}
                  />
                  <SearchButton onClick={() => handleSubmit()}>Search</SearchButton>
                </InputWrapper>
              </Content>
            </div>
          </div>
        </HeroBody>
      </Hero>
    </HeroContainer>
  )
}
export default HeroImageWrapper
