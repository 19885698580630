import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import TileComponent from '../components/Tile'
import { Theme, LinkIcon } from '@pga/pga-component-library'

const HeaderResponsive = styled.h2`
  color: ${Theme.colors.primary};
  margin: 0;
  @media (max-width: 767.98px) {
    font-size: 30px;
  }
  @media (max-width: 575.98px) {
    font-size: 22px;
  }
  @media (max-width: 375px) {
    font-size: 18px;
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0;
  @media(min-width: 576px) {
    padding: 1.5rem;
  }
  a {
    font-weight: 500;
    font-size: .85rem;
  }
`

export const Tile = ({ data: { title, image, link } }) => (
  <div className='col-lg-4 col-md-6'>
    <TileComponent {...{ title, image, link }} />
  </div>
)

const TileFactory = ({ data }) => {
  return (
    <div className='container'>
      <HeaderWrapper>
        <HeaderResponsive>{data.title}</HeaderResponsive>
        <Link to={data.link} className='text-info'>Read More<LinkIcon className='pl-2' /></Link>
      </HeaderWrapper>
      <div className='row'>
        {data.child && data.child.map((data, idx) => <Tile {...{ data }} key={idx} />)}
      </div>
    </div>
  )
}

export default TileFactory
