import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import HeroImage from '../components/HeroImage'
import TileFactory from '../factory/Tile'

export default ({ pageContext, location, data }) => {
  const { frontmatter } = pageContext
  const { childImageSharp } = data.file

  return (
    <Layout {...{ location, subNavFixed: true }}>
      <HeroImage {...{ childImageSharp }} />
      {frontmatter.tiles && frontmatter.tiles.map((data, idx) => <TileFactory {...{ data }} key={idx} />)}
    </Layout>
  )
}

export const query = graphql`
  query {
    file(relativePath: { regex: "/resources-header-image.png/" }) {
      relativePath
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
